function editCart(url) {
    $.ajax({url: url, success: function(result){
            //console.log(result);
            //  $("#cart").html(result);
            $("#cartQty").html(result.qty);
            var cartDivQty = document.getElementsByClassName('cartDivQty');
            for(var i = 0; i < cartDivQty.length; i++)
                $(cartDivQty[i]).html(result.qty);

            var cartDivPrice = document.getElementsByClassName('cartDivPrice');
            for(var i = 0; i < cartDivPrice.length; i++)
                $(cartDivPrice[i]).html(result.price);

            $("#product_qty"+result.id).html(result.product_qty);

            $("#cartQtyInput").val(result.qty);

            var cartDiv = document.getElementsByClassName('cartDiv');
            if ($("#cartQtyInput").val()>0) {
                for(var i = 0; i < cartDiv.length; i++)
                    $(cartDiv[i]).show();
            } else {
                for(var i = 0; i < cartDiv.length; i++)
                    $(cartDiv[i]).hide();

            }

            // Se Sono nella pagina del carrello (esiste il div #cart) ricarico la pagina per aggiornare
            // l'intero carrello (e non solo l'icona in alto)
            if ( $( "#cart" ).length ) {
                location.reload();
            }
        }});
}